<template>
  <div class="w-100">
    <div class="w-100 p-3 rounded" v-background-1 v-if="commande.etat_id == 1">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h4 class="m-0">Ajoutez les articles nécessaires</h4>
          <p class="m-0">Pour terminer, validez la demande</p>
        </div>
        <p class="m-0 border border-primary rounded p-3">Total : {{ getTotalPriceDemande().toFixed(2) }} € H.T</p>
      </div>
      <div class="d-flex flex-wrap">
        <div v-for="article in articles" :key="article.id" class="p-1">
          <CatalogueItem :article="article" :commandeArticles="commandeArticles" :commande="commande"></CatalogueItem>
        </div>
      </div>
    </div>
    <div class="pt-1" v-if="commande.etat_id > 1">
      <div v-for="cmdArticle in commandeArticles" :key="cmdArticle.id">
        <div
          class="d-flex justify-content-between align-items-center mb-1 p-1 shadow-sm rounded pl-2 pr-2"
          v-if="cmdArticle.commande_id == commande.id"
          v-background-3
        >
          <div class="d-flex flex-column">
            <description-from-list
              v-if="cmdArticle.article_id && articles"
              :value="cmdArticle.article_id"
              :data="articles"
            ></description-from-list>
            <small>
              <div class="text-muted">Prix unitaire : {{ getArticlePrice(cmdArticle.article_id) }} € H.T</div>
            </small>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div class="d-flex flex-column mr-2 p-2" style="width: 200px">
              <small>
                <div>quantité demandée : {{ cmdArticle.quantite }}</div>
                <div>
                  Prix :
                  {{ (getArticlePrice(cmdArticle.article_id) * cmdArticle.quantite).toFixed(2) }}
                  € HT
                </div>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5 text-center w-100 p-1">
        <div class="">Total de la commande : {{ getTotalPriceDemande().toFixed(2) }} € H.T</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import CatalogueItem from "@/components/materiels/DemandesItemArticlesItem.vue";

export default {
  name: "CommandesItem",
  components: {
    DescriptionFromList,
    CatalogueItem,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
      selectedArticle: null,
      quantity: 0,
    };
  },
  props: ["commande"],
  computed: {
    ...mapMultiRowFields("materiel", ["commandeArticles", "articles"]),
  },
  methods: {
    getArticlePrice(id) {
      let prix = 0;
      this.articles.forEach((article) => {
        if (article.id === id) {
          prix = article.prix_unitaire_ht;
        }
      });
      return prix;
    },
    getTotalPriceDemande() {
      let prix = 0;
      this.commandeArticles.forEach((cmdArticle) => {
        if (cmdArticle.commande_id == this.commande.id)
          prix = prix + this.getArticlePrice(cmdArticle.article_id) * cmdArticle.quantite;
      });
      return prix;
    },
  },
};
</script>
<style lang="css"></style>
