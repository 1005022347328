<template>
  <div class="d-flex flex-column flex-grow-1 mb-2 rounded">
    <BaseToolBar title="Vos demandes d'achat" subtitle="Liste des demandes enregistrées">
      <BaseButton
        class="btn btn-primary ml-3"
        v-tooltip="'Ajouter une demande'"
        @click="storeCommande"
        :loading="loading"
        icon="plus"
        text="Ajouter"
      ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="flex-grow-1 d-flex flex-column">
        <div v-for="commande in mesDemandes" :key="'actives_' + commande.id">
          <commandes-item :commande="commande"></commandes-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";

import BaseButton from "@/components/bases/Button.vue";
import CommandesItem from "@/components/materiels/DemandesItem.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

export default {
  components: {
    CommandesItem,
    BaseToolBar,
    BaseButton,
  },
  data() {
    return {
      loading: false,
      feedback: {},
    };
  },
  methods: {
    ...mapActions({
      _storeCommande: "materiel/createCommande",
    }),
    storeCommande: function () {
      this.feedback = {};
      this.loading = true;
      this._storeCommande()
        //.catch(error => ())
        .finally(() => (this.loading = false));
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      data: "materiel/data",
      user: "user/data",
      haveRole: "user/haveRole",
    }),
    ...mapMultiRowFields("materiel", ["commandes"]),
    mesDemandes() {
      if (this.haveRole("admin")) return this.commandes;
      return this.commandes.filter((cmd) => cmd.user_id == this.user.id);
    },
  },
};
</script>
<style lang="css"></style>
