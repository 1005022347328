<template>
  <div
    class="rounded p-3 shadow-sm d-flex flex-column align-items-center justify-content-between"
    v-background-3
    style="width: 9rem; height: 100%"
  >
    <AppAvatar :url="article.image" propSize="4rem" :rounded="false" class="mb-2"></AppAvatar>
    <div class="mb-2 text-center">
      <small>{{ article.description }}</small>
    </div>
    <div class="d-flex rounded-xl align-items-center" :class="qwarkBorder">
      <ButtonCircle name="minus" @click="moins(article.id)" class="m-1" :loading="loading"></ButtonCircle>
      <div>{{ articleCounter }}</div>
      <ButtonCircle name="plus" @click="plus(article.id)" class="m-1" :loading="loading"></ButtonCircle>
    </div>
    <div class="text-muted">
      <small> {{ article.prix_unitaire_ht }} € H.T </small>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
//import { mapGetters } from "vuex";
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import AppAvatar from "@/components/bases/Avatar.vue";

export default {
  components: {
    ButtonCircle,
    AppAvatar,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: ["article", "commande", "commandeArticles"],
  computed: {
    articleCounter() {
      const exists = this.commandeArticles.filter(
        (cmd) => cmd.article_id == this.article.id && cmd.commande_id == this.commande.id
      );
      if (exists[0] && exists[0].quantite) return exists[0].quantite;
      return 0;
    },
    qwarkBorder() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light-2";
      } else {
        return "qwark-border-dark-2";
      }
    },
  },
  methods: {
    ...mapActions({
      _createCommandeArticle: "materiel/createCommandeArticle",
      _updateCommandeArticle: "materiel/updateCommandeArticle",
      _deleteCommandeArticle: "materiel/deleteCommandeArticle",
    }),
    plus() {
      this.feedback = {};
      this.loading = true;
      if (this.articleCounter > 0) {
        this._updateCommandeArticle({
          id: this.commandeArticles.filter(
            (cmd) => cmd.article_id == this.article.id && cmd.commande_id == this.commande.id
          )[0].id,
          commande_id: this.commande.id,
          article_id: this.article.id,
          quantite: this.articleCounter + 1,
        })
          .catch((error) => (this.feedback = errHandler(error)))
          .finally(() => (this.loading = false));
      } else {
        this._createCommandeArticle({
          commande_id: this.commande.id,
          article_id: this.article.id,
          quantite: 1,
        })
          .catch((error) => (this.feedback = errHandler(error)))
          .finally(() => (this.loading = false));
      }
    },
    moins() {
      this.feedback = {};
      this.loading = true;
      if (this.articleCounter > 1) {
        this._updateCommandeArticle({
          id: this.commandeArticles.filter(
            (cmd) => cmd.article_id == this.article.id && cmd.commande_id == this.commande.id
          )[0].id,
          commande_id: this.commande.id,
          article_id: this.article.id,
          quantite: this.articleCounter - 1,
        })
          .catch((error) => (this.feedback = errHandler(error)))
          .finally(() => (this.loading = false));
      } else {
        this._deleteCommandeArticle({
          id: this.commandeArticles.filter(
            (cmd) => cmd.article_id == this.article.id && cmd.commande_id == this.commande.id
          )[0].id,
        })
          .catch((error) => (this.feedback = errHandler(error)))
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>
<style lang="css">
.qwark-border-light-2 {
  border: 1px solid #dfe3e5 !important;
}
.qwark-border-dark-2 {
  border: 1px solid #24292d !important;
}
</style>
