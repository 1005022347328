<template>
  <container-list-extend title="Commande">
    <template v-slot:title>
      <div class="p-1 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <span>Demande N°{{ commande.id }}</span>
      </div>
      <div class="text-muted pl-2 pr-2 pl-md-3 pr-md-3 d-flex align-items-center">
        <small>
          <description-from-list :value="commande.etat_id" :data="data.etats" text="à saisir"></description-from-list>
        </small>
      </div>
    </template>
    <template v-slot:form>
      <div class="pt-3 pl-3 pr-3">
        <div v-if="commande.etat_id == 2" class="alert alert-warning">La demande est en cours de traitement</div>
        <div v-if="commande.etat_id == 3" class="alert alert-primary">La livraison est en cours</div>
        <div v-if="commande.etat_id == 4" class="alert alert-success">Le matériel est livré</div>
      </div>
      <div class="pl-2 pr-2 pl-lg-4 pr-lg-4">
        <div v-if="commande.etat_id == 1">
          <div class="d-flex align-items-center">
            <div>
              <BaseIcon name="box" width="34" height="34" class="mr-3"></BaseIcon>
            </div>
            <div>
              <h4 class="m-0">Demande d'achat</h4>
              <p class="m-0 text-muted">Renseignez les champs ci-dessous, choisissez vos articles et valider</p>
            </div>
          </div>
          <base-form-row row>
            <base-input-area
              v-model="commande.contact_livraison"
              text="Contact pour la livraison (indiquez nom, prénom et numéro de téléphone)"
              :errors="feedback.contact_livraison"
            ></base-input-area>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Sélectionnez un établissement pour la livraison"
              v-model.number="commande.etablissement_livraison_id"
              :options="data.etablissements"
              :errors="feedback.etablissement_livraison_id"
              @input="etablSelected"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-select
              inputText="Indiquez la société (facturation)"
              v-model.number="commande.societe_facturation_id"
              :options="data.societes"
              :errors="feedback.societe_facturation_id"
            ></base-select>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              v-model="commande.adresse_livraison"
              text="Adresse de livraison (si différente d'un établissement du groupe)"
              :errors="feedback.adresse_livraison"
            ></base-input-area>
          </base-form-row>
          <base-form-row row>
            <base-input-area
              v-model="commande.commentaire"
              text="Commentaire"
              :errors="feedback.commentaire"
            ></base-input-area>
          </base-form-row>
        </div>
        <div class="mb-3" v-if="commande.etat_id !== 1">
          <div v-if="commande.contact_livraison" class="text-muted">
            Contact pour la livraison :
            {{ commande.contact_livraison }}
          </div>
          <div v-if="commande.adresse_livraison" class="text-muted">
            Adresse de livraison :
            {{ commande.adresse_livraison }}
          </div>
          <div v-if="commande.commentaire" class="text-muted">
            Commentaire du demandeur :
            {{ commande.commentaire }}
          </div>
        </div>
      </div>
      <div class="d-flex p-3" v-if="commande.etat_id == 1">
        <button class="btn btn-danger" @click="deleteCommande(commande)">Supprimer</button>
        <button class="btn btn-primary ml-auto" @click="updateCommandeEtat(2)">valider la demande</button>
      </div>
    </template>
    <template v-slot:actions>
      <CommandesItemArticles :commande="commande"></CommandesItemArticles>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions } from "vuex";
import { errHandler } from "@/helpers.js";
import { mapGetters } from "vuex";

//import BaseIcon from "@/components/bases/Icon.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
//import BaseForm from "@/components/bases/Form.vue";
import DescriptionFromList from "@/components/bases/DescriptionFromList";
import BaseInputArea from "@/components/bases/InputArea.vue";

import BaseFormRow from "@/components/bases/FormRow.vue";
//import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import CommandesItemArticles from "@/components/materiels/DemandesItemArticles";
import BaseIcon from "@/components/bases/Icon.vue";

export default {
  name: "CommandesItem",
  components: {
    //BaseIcon,
    ContainerListExtend,
    //BaseForm,
    BaseFormRow,
    //BaseInput,
    BaseSelect,
    DescriptionFromList,
    CommandesItemArticles,
    BaseInputArea,
    BaseIcon,
  },
  data() {
    return {
      feedback: {},
      loading: false,
      statut: null,
    };
  },
  props: ["commande"],
  computed: {
    ...mapGetters({
      data: "materiel/data",
    }),
  },
  methods: {
    ...mapActions({
      _updateCommande: "materiel/updateCommande",
      _deleteCommande: "materiel/deleteCommande",
    }),
    updateCommande(commande) {
      this.feedback = {};
      this.loading = true;
      this._updateCommande(commande)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteCommande(commande) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteCommande(commande)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    updateCommandeEtat(etat_id) {
      this.commande.etat_id = etat_id;
      this.updateCommande(this.commande);
    },
    etablSelected() {
      const index = this.data.etablissements.findIndex((x) => x.id === this.commande.etablissement_livraison_id);
      const etab = this.data.etablissements[index];
      this.commande.adresse_livraison = etab.adresse;
    },
  },
};
</script>
<style lang="css"></style>
